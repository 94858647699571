import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["topBar", "breadcrumbs", "navWrapper"];

  get navWrapperHeight() {
    const nav = document.querySelector("#top-navigation-bar");
    // Check if nav is collapsed (when resolution is high)
    if (nav && window.devicePixelRatio >= 2 && window.matchMedia("(hover: hover)").matches) {
      const header = document.querySelector("header.navigation");
      return header?.matches(":hover") ? nav.offsetHeight : 0;
    }
    return nav?.offsetHeight || 0;
  }

  get topBarHeight() { return this.topBarTarget.offsetHeight; }

  get breadcrumbsHeight() { return this.breadcrumbsTarget.offsetHeight; }

  get docBodyWrapper() { return document.querySelector("#doc-body-wrapper"); }

  connect() {
    this.lastScrollTop = 0;
    this.delta = 5;

    new ResizeObserver(() => {
      this.handleResize();
    }).observe(document.body);

    if (this.docBodyWrapper !== null) {
      this.docBodyWrapper.style.paddingTop = `${this.breadcrumbsHeight + this.topBarHeight}px`;
    }

    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll() {
    const currentScroll = window.scrollY;

    // Check scroll direction
    if (Math.abs(this.lastScrollTop - currentScroll) <= this.delta) return;

    if (currentScroll > this.lastScrollTop) {
      // Scrolling down
      if (currentScroll > (this.topBarHeight + this.breadcrumbsHeight)) {
        this.topBarTarget.style.top = `-${this.topBarHeight}px`;
        this.breadcrumbsTarget.style.top = `-${this.breadcrumbsHeight}px`;
      }
    } else {
      // Scrolling up
      this.topBarTarget.style.transition = "top 0.3s ease-in-out";
      this.topBarTarget.style.top = `${this.breadcrumbsHeight + this.navWrapperHeight}px`;
      this.breadcrumbsTarget.style.top = `${this.navWrapperHeight}px`;
    }

    this.lastScrollTop = currentScroll;
  }

  handleResize() {
    this.topBarTarget.style.top = `${this.breadcrumbsHeight + this.navWrapperHeight}px`;
    this.breadcrumbsTarget.style.top = `${this.navWrapperHeight}px`;
    if (this.docBodyWrapper !== null) {
      this.docBodyWrapper.style.paddingTop = `${this.breadcrumbsHeight + this.topBarHeight}px`;
    }
  }
}