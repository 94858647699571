import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["display", "pauseTime"];

  initialize() {
    const modalIds = [
      "pause-modal-id",
      "pause-rationale-modal-id",
      "pause-expiration-modal-id",
      "warning-modal-id",
      "expiration-modal-id"
    ];
    modalIds.forEach(id => this.hideModal(this.data.get(id)));

    if (this.hasDisplayTarget || this.hasPauseTimeTarget) {
      this.examSeconds = parseInt(this.data.get("starting-seconds"));
      this.warningSeconds = parseInt(this.data.get("warning-seconds"));
      this.pauseRemaining = parseInt(this.data.get("pause-remaining-seconds"));

      this.startExamCountdown();
      this.startWarningCountdown();
      const pauseTimer = document.getElementById("pauseTimer");
      if (pauseTimer) {
        pauseTimer.style.display = "none";
      }

      if (parseInt(this.data.get("pause-remaining-seconds")) > 0) {
        this.pausesExam(parseInt(this.data.get("pause-remaining-seconds")));
        this.showModal('pauseModal');
      }
    }
    this.setupEventListeners();
  }

  startExamCountdown() {
    this.examEndTime = new Date().getTime() + this.examSeconds * 1000;
    this.examInterval = setInterval(() => {
      if (!this.displayTarget) {
        clearInterval(this.examInterval);
        return;
      }

      const now = new Date().getTime();
      const timeLeft = this.examEndTime - now;

      if (timeLeft <= 0) {
        clearInterval(this.examInterval);
        this.onExpired();
        return;
      }
      const endTime = now + timeLeft;

      // Now, calculate the remaining time using countdown.js by specifying endTime
      const remaining = countdown(new Date(endTime), new Date(), countdown.HOURS | countdown.MINUTES | countdown.SECONDS);
      this.updateDisplay(remaining);
    }, 1000);
  }

  startWarningCountdown() {
    this.warningEndTime = new Date().getTime() + this.warningSeconds * 1000;
    this.warningInterval = setInterval(() => {
      const now = new Date().getTime();
      const timeLeft = this.warningEndTime - now;

      if (timeLeft <= 0) {
        this.displayWarning();
        this.displayWarningModal(timeLeft);
        clearInterval(this.warningInterval);
      }
    }, 1000);
  }

  updateDisplay(remaining) {
    if (!this.displayTarget) return;

    const hours = remaining.hours.toString().padStart(2, "0");
    const minutes = remaining.minutes.toString().padStart(2, "0");
    const seconds = remaining.seconds.toString().padStart(2, "0");
    this.displayTarget.innerHTML = `${hours}:${minutes}:${seconds}`;
  }

  setupEventListeners() {
    const modals = {
      pauseRationale: document.getElementById(this.data.get("pause-rationale-modal-id")),
      warning: document.getElementById(this.data.get("warning-modal-id")),
      pauseExpiration: document.getElementById(this.data.get("pause-expiration-modal-id")),
      pause: document.getElementById(this.data.get("pause-modal-id")),
      expiration: document.getElementById(this.data.get("expiration-modal-id"))
    };

    // Setup pause rationale modal events
    if (modals.pauseRationale) {
      this.addClickHandler(modals.pauseRationale.querySelector("#submit-pause-btn"), (e) => {
        e.preventDefault();
        this.pauseRationaleModalClosed();
      });

      const closeSelectors = [".btn-close", "#pause-cancel"];
      closeSelectors.forEach(selector => {
        this.addClickHandler(modals.pauseRationale.querySelector(selector), (e) => {
          e.preventDefault();
          this.hideModal('pauseRationaleModal');
        });
      });
    }

    // Setup warning modal events
    if (modals.warning) {
      const closeSelectors = [".btn-close", ".cancl"];
      closeSelectors.forEach(selector => {
        this.addClickHandler(modals.warning.querySelector(selector), (e) => {
          e.preventDefault();
          this.hideModal('warningModal');
        });
      });
    }

    // Setup pause expiration modal events
    if (modals.pauseExpiration) {
      this.addClickHandler(modals.pauseExpiration.querySelector(".cancl"), (e) => {
        e.preventDefault();
        this.hideModal('pauseExpirationModal');
        this.autosubmittedModalClosed();
      });
    }

    // Setup pause modal events
    if (modals.pause) {
      this.addClickHandler(modals.pause.querySelector("#resume-pause-btn"), (e) => {
        e.preventDefault();
        this.hideModal('pauseModal');
        this.pauseModalClosed();
      });
    }

    // Setup expiration modal events
    if (modals.expiration) {
      this.addClickHandler(modals.expiration.querySelector("#auto-submit"), (e) => {
        e.preventDefault();
        this.autosubmittedModalClosed();
      });
    }
  }

  addClickHandler(element, handler) {
    if (element) {
      element.addEventListener("click", handler);
    }
  }

  addEscapeKeyListener() {
    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        document.querySelectorAll('.btn-close').forEach(button => button.click());
      }
    });
  }

  showPauseRationaleModal(ev) {
    this.showModal(this.data.get("pause-rationale-modal-id"));
    const reasonInput = document.getElementById("reason");
    const submitButton = document.getElementById("submit-pause-btn");

    if (reasonInput && submitButton) {
      reasonInput.addEventListener("input", function () {
        submitButton.disabled = reasonInput.value.trim() === "";
      });

      submitButton.addEventListener("click", this.hidePausebtn);
    }
  }

  hidePausebtn() {
    const pauseButton = document.getElementById("pauseButton");
    if (pauseButton) {
      setTimeout(() => {
        pauseButton.style.display = "none";
      }, 1000);
    }
  }

  pauseRationaleModalClosed() {
    const reasonInput = document.getElementById("reason");
    const errorMessage = document.getElementById("errorMessage");

    if (!reasonInput) return;

    const pauseReason = reasonInput.value;
    if (pauseReason === "") {
      if (errorMessage) {
        errorMessage.style.display = "block";
      }
      this.showModal(this.data.get("pause-rationale-modal-id"));
    } else {
      this.pause(pauseReason);
      this.hideModal('pauseRationaleModal');
      this.showModal('pauseModal');
    }
  }

  pause(pauseReason) {
    this.pausesExam(parseInt(this.data.get("pause-seconds")));
    const pauseUrl = document.getElementById("pause-url").value;
    const rostering = { pause_reason: pauseReason };
    const token = document.querySelector('meta[name="csrf-token"]').content;

    fetch(pauseUrl, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
      body: JSON.stringify({ rostering: rostering })
    });
  }

  pausesExam(pauseSeconds) {
    this.pauseEndTime = new Date().getTime() + pauseSeconds * 1000;
    this.pauseInterval = setInterval(() => {
      const remaining = countdown(new Date(),this.pauseEndTime, countdown.HOURS | countdown.MINUTES | countdown.SECONDS);
      if (remaining.value <= 0) {
        this.submitExam();
        clearInterval(this.pauseInterval);
      } else {
        this.displayPauseTimer(remaining);
      }
    }, 1000);

    clearInterval(this.examInterval);
    clearInterval(this.warningInterval);

    const pauseTimer = document.getElementById("pauseTimer");
    const examTimer = document.getElementById("examTimer");
    const pauseButton = document.getElementById("pauseButton");

    if (pauseTimer) pauseTimer.style.display = "block";
    if (examTimer) examTimer.style.display = "none";
    if (pauseButton) pauseButton.disabled = true;
  }

  pauseModalClosed() {
    this.resume();
  }

  resume() {
    this.startExamCountdown();
    this.startWarningCountdown();

    const pauseTimer = document.getElementById("pauseTimer");
    const examTimer = document.getElementById("examTimer");

    if (pauseTimer) pauseTimer.style.display = "none";
    if (examTimer) examTimer.style.display = "block";
    clearInterval(this.pauseInterval);

    const rostering = { pause_interval: this.pauseRemaining };
    const resumeUrl = document.getElementById("resume-url").value;
    const token = document.querySelector('meta[name="csrf-token"]').content;

    fetch(resumeUrl, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      },
      body: JSON.stringify({ rostering: rostering })
    });
  }

  submitExam() {
    const timedout = document.getElementById("timedout");
    if (timedout) {
      timedout.value = "timedout";
    }
    this.hideModal(this.data.get("pause-modal-id"));
    this.showModal(this.data.get("pause-expiration-modal-id"));
  }

  displayPauseTimer(remaining) {
    const pauseTime = document.getElementById("pauseTime");
    if (!pauseTime) return;

    const hours = remaining.hours.toString().padStart(2, "0");
    const minutes = remaining.minutes.toString().padStart(2, "0");
    const seconds = remaining.seconds.toString().padStart(2, "0");
    pauseTime.innerHTML = `${hours}:${minutes}:${seconds}`;
  }

  onExpired() {
    clearInterval(this.examInterval);
    this.showModal(this.data.get("expiration-modal-id"));
    const timedout = document.getElementById("timedout");
    if (timedout) {
      timedout.value = "timedout";
    }
  }

  displayWarning() {
    const timer = document.getElementById("timer");
    if (timer) {
      timer.classList.add("red_background");
    }
  }

  // Display the warning modal only once
  displayWarningModal(timeLeft) {
    if (timeLeft > -2000) {
      this.showModal(this.data.get("warning-modal-id"));
    }
  }

  autosubmittedModalClosed() {
    const redirectUrl = document.getElementById("redirect-url").value;
    this.autosubmitted();
    window.location = redirectUrl;
  }

  autosubmitted() {
    const autoSubmitUrl = document.getElementById("autosubmit-url").value;
    const token = document.querySelector('meta[name="csrf-token"]').content;

    fetch(autoSubmitUrl, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    });
  }

  showModal(modalId) {
    const modal = document.getElementById(modalId);
    if (modal) {
      modal.style.display = "block";
      document.body.style.overflow = "hidden";
      this.addEscapeKeyListener();
    }
  }

  hideModal(modalId) {
    const modal = document.getElementById(modalId);
    if (modal) {
      modal.style.display = "none";
      document.body.style.overflow = "auto";
    }
  }
}