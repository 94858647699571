import {full_url} from 'helpers/url'
import { updateProgress } from '../helpers/progressHelper';

export const EXPANDED_MOD_MAP_LOG = "ExpandedModMapLog"
export const OPENED_DOCUMENT_LOG = "OpenedDocumentLog"
export const OPENED_LAB_VALUES_LOG = "OpenedLabValuesLog"
export const OPENED_MOD_MAP_LOG = "OpenedModMapLog"
export const OPENED_SECTION_LOG = "OpenedSectionLog"

export function postDocumentUserLog(document_id, log_type, args={}) {
  fetch(full_url(`/documents/${document_id}/document_user_logs`), {
    method: 'POST',
    headers: ReactOnRails.authenticityHeaders({
      'Accept': "application/json",
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify({
      document_user_log: {
        type: log_type,
        created_at: Math.floor(Date.now() / 1000),
        ...args
      }
    })
  })
  .then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  })
  .then(data => {
    updateProgress(data);
  })
  .catch(error => {
    console.error(error);
    Rollbar.warning("Logging Data Failed");
  });
}
