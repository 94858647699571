import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["cardLoader", "cardContent", "questionModal"];

  connect() {
    if (this.hasCardLoaderTarget && this.hasCardContentTarget) {
      this.loadCardContent();
    }
  }

  loadCardContent() {
    const cardName = this.element.dataset.cardName;
    const cardLoader = this.cardLoaderTarget;
    const cardContent = this.cardContentTarget;
    cardLoader.style.display = "block";

    fetch(`/educator_dashboard/${cardName}`)
      .then(response => response.text())
      .then(html => {
        cardContent.innerHTML = html;
      })
      .catch(error => {
        console.error("Error fetching data:", error);
        cardContent.innerHTML = "Error fetching data: " + error.message;
      })
      .finally(() => {
        cardLoader.style.display = "none";
        cardContent.style.opacity = "1";
      });
  }

  openQuestionModal(event){
    event.preventDefault();
    analytics.track('questions_about_data', {page: window.location.href, source: "Dashboard"})
    this.questionModalTarget.classList.remove("hidden");
    document.addEventListener("click", this.closeQuestionModal.bind(this));
  }

  closeQuestionModal(event){
    if(event.target.id === 'questionModal'){
      this.questionModalTarget.classList.add("hidden");
    }
  }
}