document.addEventListener("DOMContentLoaded", function() {
  document.addEventListener('click', function(event) {
    if (event.target && event.target.id === 'reportTab') {
      var nextTab = document.querySelector('#reportTabs a[href="#' + event.target.dataset.next + '"]');
      if (nextTab) {
        var bootstrapTab = new bootstrap.Tab(nextTab);
        bootstrapTab.show();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
      }
    }
  });
});
