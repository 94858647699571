import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["card", "nextBtn", "prevBtn", "toHonorCode"];

  connect() {
    this.currentCardIndex = 0;

    if (this.cardTargets.length > 0) {
      this.cardTargets[this.currentCardIndex].classList.add('active');
    }
  }

  goToNextCard() {
    if (this.currentCardIndex < this.cardTargets.length - 1) {
      this.cardTargets[this.currentCardIndex].classList.remove('active');
      this.currentCardIndex++;
      this.cardTargets[this.currentCardIndex].classList.add('active');
    }
  }

  goToPreviousCard() {
    if (this.currentCardIndex > 0) {
      this.cardTargets[this.currentCardIndex].classList.remove('active');
      this.currentCardIndex--;
      this.cardTargets[this.currentCardIndex].classList.add('active');
    }
  }

  skipAssessment() {
    this.goToHonorCode();
  }

  goToHonorCode() {
    if (this.hasToHonorCodeTarget) {
      window.location.href = this.toHonorCodeTarget.dataset.honorCodePath;
    }
  }

  honorSubmit() {
    document.getElementById('honorCodeCheckbox').addEventListener('change', function () {
      document.getElementById('nextButton').disabled = !this.checked;
    });
  }
}
