import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['galleryContainer' ,'modalImage', 'modalContentImage', 'prevArrow', 'nextArrow'];

  get dots() {
    return document.querySelectorAll('[data-ref="image"]');
  }
  get slideContainer() {
    return document.querySelector('.slides');
  }
  get indicator() {
    return document.querySelector('#indicator');
  }
  get slides() {
    if (this.hasGalleryContainerTarget) {
      return this.galleryContainerTarget.querySelectorAll('.slide-image');
    }
    return document.querySelectorAll('.slide-image');
  }

  connect() {
    if (this.hasModalContentImageTarget) {
      this.modalContentImageTarget.onclick = this.closeModalImage.bind(this);
    }

    let currentIndex = "0";
    if (this.slides.length > 1) {
      const observer = new IntersectionObserver(function (entries, observer) {
        const activated = entries.reduce(function (max, entry) {
          return (entry.intersectionRatio > max.intersectionRatio) ? entry : max;
        });
        if (activated.intersectionRatio > 0) {
          currentIndex = activated.target.dataset.index;
          this.updateIndicator(currentIndex);
        }
      }.bind(this), { root: this.slideContainer, threshold: 0.5 });

      for (const slide of this.slides) {
        observer.observe(slide);
      }
    }

    for (const dot of this.dots) {
      dot.addEventListener("click", this.scrollToImage.bind(this));
    }
    if (this.hasPrevArrowTarget) {
      this.prevArrowTarget.addEventListener("click", this.scrollToPrevious.bind(this));
    }
    if (this.hasNextArrowTarget) {
      this.nextArrowTarget.addEventListener("click", this.scrollToNext.bind(this));
    }
    document.addEventListener('keydown', this.handleKeyDown.bind(this));
  }

  disconnect() {
    document.removeEventListener('keydown', this.handleKeyDown.bind(this));
  }

  expandImage(e) {
    this.modalImageTarget.style.display = "flex";
    this.modalImageTarget.classList.add("open");
    document.body.classList.add('modal-open');
    this.modalContentImageTarget.src = e.currentTarget.src;
  }

  expandImageIcon(e) {
    this.modalImageTarget.style.display = "flex";
    this.modalImageTarget.classList.add("open");
    document.body.classList.add("modal-open");
    this.modalContentImageTarget.src = e.currentTarget.parentElement.firstElementChild.src;
  }

  closeModalImage() {
    if (this.hasModalImageTarget) {
      this.modalImageTarget.style.display = "none";
      this.modalImageTarget.classList.remove("open");
    }
    document.body.classList.remove('modal-open');
  }

  handleKeyDown(event) {
    if (event.key === 'Escape' && this.modalImageTarget.classList.contains('open')) {
      this.closeModalImage();
    }
  }

  updateIndicator(currentIndex) {
    for (const slide of this.slides) {
      if (slide.dataset.index === currentIndex) {
        this.dots[currentIndex].classList.add("current");
        this.updatePositions(slide);
      } else {
        this.dots[slide.dataset.index].classList.remove("current");
      }
    }
  }

  scrollToPrevious() {
    const current = document.querySelector('[data-position="current"]');
    const previous = document.querySelector('[data-position="previous"]');

    if (previous) {
      previous.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      });
      previous.dataset.position = "current";
      current.dataset.position = "next";
      const newPrev = document.querySelector(`[data-index="${parseInt(previous.dataset.index) - 1}"]`);
      if (newPrev) {
        newPrev.dataset.position = "previous"
      }
      document.querySelector('.dot.current')?.classList.remove("current");
      document.querySelector(`[data-ref-id="${previous.id}"]`)?.classList.add("current");
    } else {
      this.scrollToLast();
    }
  }

  scrollToNext() {
    const current = document.querySelector('[data-position="current"]');
    const next = document.querySelector('[data-position="next"]');

    if (next) {
      next.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      });
      next.dataset.position = "current";
      current.dataset.position = "previous";
      const newNext = document.querySelector(`[data-index="${parseInt(next.dataset.index) + 1}"]`);
      if (newNext) {
        newNext.dataset.position = "next";
      }
      document.querySelector('.dot.current')?.classList.remove("current");
      document.querySelector(`[data-ref-id="${next.id}"]`)?.classList.add("current");
    } else {
      this.scrollToFirst();
    }
  }

  scrollToLast() {
    const lastIndex = this.dots.length - 1;
    const last = document.querySelector(`[data-index="${lastIndex}"]`);
    const previous = document.querySelector(`[data-index="${lastIndex - 1}"]`);
    last.dataset.position = "current";
    previous.dataset.position = "previous";
    last.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    });
    document.querySelector('.dot.current')?.classList.remove("current");
    document.querySelector(`[data-ref-id="${last.id}"]`)?.classList.add("current");
  }

  scrollToFirst() {
    const first = document.querySelector('#image-0');
    const next = document.querySelector('#image-1');
    first.dataset.position = "current";
    next.dataset.position = "next";
    first.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    });
    document.querySelector('.dot.current')?.classList.remove("current");
    document.querySelector('[data-ref-id="image-0"]')?.classList.add("current");
  }

  scrollToImage(e) {
    if (e.currentTarget.classList.contains('current')) {
      return;
    }
    const target = document.querySelector(`#${e.currentTarget.dataset.refId}`);
    this.updatePositions(target);
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    });
    document.querySelector('.dot.current')?.classList.remove("current");
    e.currentTarget.classList.add("current");
  }

  updatePositions(target) {
    const current = document.querySelector('[data-position="current"]');
    const next = document.querySelector('[data-position="next"]');
    const previous = document.querySelector('[data-position="previous"]');

    current.dataset.position = "";
    if (next) {
      next.dataset.position = "";
    }
    if (previous) {
      previous.dataset.position = "";
    }

    target.dataset.position = "current";

    const index = parseInt(target.dataset.index);
    if (index !== 0) {
      document.querySelector(`[data-index="${index - 1}"]`).dataset.position = "previous";
    }
    if (index !== this.dots.length - 1) {
      document.querySelector(`[data-index="${index + 1}"]`).dataset.position = "next";
    }
  };
}
