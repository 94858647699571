export function updateProgress(data: any): void {
  if (data.progress !== undefined && data.progress !== 0) {
    const progressBar = document.querySelector('.progress-text');
    const progress = data.progress + '%';

    if (progressBar && progress === '100%') {
      document.querySelector('.progress-text')?.classList.add('hide');
      document.querySelector('.progress-box')?.classList.add('hide');
      document.querySelector('.complete-progress-icon')?.classList.remove('hide');
      document.querySelector('.my-time')?.classList.remove('hide');
      document.querySelector('.my-time-content')!.innerHTML ="My time: <br/> &nbsp;&nbsp;&nbsp; " + Math.round(data.time_on_case / 1000 / 60).toString() + " min";
      document.getElementById('resetQuestionButton')?.classList.remove('hide');
    } else if (progressBar) {
      document.querySelector('.progress-box')?.classList.remove('hide');
      document.querySelector('.progress-text')!.textContent = progress + " Complete";
      document.querySelector<HTMLElement>('.progress-bar-length')!.style.width =progress;
    }
  }
}