import { Controller } from "@hotwired/stimulus";
import aquiferLogoExpandedImg from '../../assets/images/Aquifer-powered-by-Aqueduct.png';
import aquiferLogoCollapsedImg from '../../assets/images/Aquifer_logo_swoosh_white.svg';

export default class extends Controller {
  static targets = ["navigation", "sideNavigation"];

  connect() {
    this.toggleSideNavigation(); // Initial state on page load
    window.addEventListener("resize", this.toggleSideNavigation.bind(this));
    const navState = sessionStorage.getItem('sideNavState');
    const resourceMenuState = sessionStorage.getItem('resourceMenuState');
    const defaultState = this.data.get('default-state')
    const featureFlagEnabled = this.data.get("featureFlagEnabled") === "true";
    const todoMenuState = sessionStorage.getItem('todoMenuState');
    const quizMenuState = sessionStorage.getItem('quizMenuState');

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (isSafari) {
    document.documentElement.classList.add('safari');
    }

    if (featureFlagEnabled) {
      if (defaultState ==='collapsed' || navState === 'collapsed') {
        this.collapseNavigation();
      }
      else {
        this.expandNavigation()
      }
    }
    if (defaultState !== 'collapsed') {
      if (resourceMenuState === null || resourceMenuState === 'collapsed') {
        this.collapseResourceMenu();
      }
      else {
        this.expandResourceMenu()
      }
      if (todoMenuState === null || todoMenuState === 'collapsed') {
        this.collapseTodoMenu();
      }
      else {
        this.expandTodoMenu()
      }
      if (quizMenuState === null || quizMenuState === 'collapsed') {
        this.collapseQuizMenu();
      }
      else{
        this.expandQuizMenu();
      }

    }
    let cookies = document.cookie.split('; ');
    let cookie = cookies.find(row => row.startsWith('programMenuState='));
    if (cookie === undefined || cookie.split('=')[1] === 'collapsed') {
      this.collapseProgramsMenu();
    }
    else {
      this.expandProgramsMenu()
    }
  }

  toggleNavigation() {
    const sideNavDiv = document.getElementById("side-nav-div");

    if (sideNavDiv.classList.contains("collapsed")) {
      this.expandNavigation();
    } else {
      this.collapseNavigation();
    }
  }

  expandNavigation() {
    const sideNavDiv = document.getElementById("side-nav-div");
    const sideNavMainContent = document.getElementById("side-nav-main-content");
    const aquiferLogoImage = document.getElementById("aquifer-logo-img");
    const collapseImg = document.getElementById("collapse-img");

    sideNavDiv.classList.remove("collapsed");
    document.getElementById("panel-sticky")?.classList?.remove("smaller-sticky-info");
    document.getElementById("panel-sticky-crumbs")?.classList?.remove("smaller-sticky-info");

    if(sideNavMainContent){
      sideNavMainContent.classList.remove("collapsed");
    }
    if(document.getElementById("side-navigation-profile-icon")){
      document.getElementById("side-navigation-profile-icon").classList.remove("collapsed");
    }
    if(document.getElementById("log-out-div")){
      document.getElementById("log-out-div").classList.remove("collapsed");
    }
    if (document.getElementById("resources-menu-img")) {
      document.getElementById("resources-menu-img").classList.remove("collapsed");
    }
    if (document.getElementById("programs-menu-img")) {
      document.getElementById("programs-menu-img").classList.remove("collapsed");
    }
    if (document.getElementById("todo-menu-img")) {
      document.getElementById("todo-menu-img").classList.remove("collapsed");
    }
    if (document.getElementById("quiz-menu-img")) {
      document.getElementById("quiz-menu-img").classList.remove("collapsed");
    }
    document.querySelectorAll(".st-accordion__list").forEach(element => {
      element.classList.remove("collapsed");
    });
    document.querySelectorAll(".side-navigation-icon-container").forEach(element => {
      element.removeAttribute("title");
    });
    aquiferLogoImage.src = aquiferLogoExpandedImg;
    aquiferLogoImage.style.marginLeft = "13px";
    collapseImg.classList.remove('right');
    collapseImg.classList.add('left');
    sessionStorage.setItem('sideNavState', 'expanded');
  }

  collapseNavigation() {
    const sideNavDiv = document.getElementById("side-nav-div");
    const sideNavMainContent = document.getElementById("side-nav-main-content");
    const aquiferLogoImage = document.getElementById("aquifer-logo-img");
    const collapseImg = document.getElementById("collapse-img");
    sideNavDiv.classList.add("collapsed");
    if (sideNavMainContent) {
      sideNavMainContent.classList.add("collapsed");
    }
    if(document.getElementById("side-navigation-profile-icon")){
      document.getElementById("side-navigation-profile-icon").classList.add("collapsed");
    }
    if(document.getElementById("log-out-div")){
      document.getElementById("log-out-div").classList.add("collapsed");
    }
    document.getElementById("panel-sticky")?.classList?.add("smaller-sticky-info");
    document.getElementById("panel-sticky-crumbs")?.classList?.add("smaller-sticky-info");
    if (document.getElementById("resources-menu-img")) {
      document.getElementById("resources-menu-img").classList.add("collapsed");
    }
    if (document.getElementById("programs-menu-img")) {
      document.getElementById("programs-menu-img").classList.add("collapsed");
    }
    document.querySelectorAll(".st-accordion__list").forEach(element => {
      element.classList.add("collapsed");
    });
    document.querySelectorAll(".side-navigation-icon-container").forEach(element => {
      const divTitle=element.querySelector("span").innerHTML
      element.setAttribute("title", divTitle);
    });
    if (document.getElementById("todo-menu-img")) {
      document.getElementById("todo-menu-img").classList.add("collapsed");
    }
    if (document.getElementById("quiz-menu-img")) {
      document.getElementById("quiz-menu-img").classList.add("collapsed");
    }

    aquiferLogoImage.src = aquiferLogoCollapsedImg;
    aquiferLogoImage.style.marginLeft = "0px";
    collapseImg.classList.remove('left');
    collapseImg.classList.add('right');
    this.collapseResourceMenu()
    this.collapseProgramsMenu()
    this.collapseTodoMenu()
    this.collapseQuizMenu()
    sessionStorage.setItem('sideNavState', 'collapsed');
  }

  toggleResourcesMenu() {
    if (sessionStorage.getItem('sideNavState')!== 'collapsed') {
      const resourcesMenuElements = document.getElementsByClassName("resources_content")
      const resourceArray = Array.from(resourcesMenuElements);
      const hasCollapsed = resourceArray.some(element => element.classList.contains("collapsed"));
      if (hasCollapsed) {
        this.expandResourceMenu();
      } else {
        this.collapseResourceMenu();
      }
    }else{
      this.expandNavigation()
      this.expandResourceMenu()
    }
  }

  expandResourceMenu() {
    const resourcesMenuElements = document.getElementsByClassName("resources_content")
    const resourceArray = Array.from(resourcesMenuElements);
    const resourcesMenuImages = document.getElementsByClassName("resources-menu-img")
    const resourcesMenuImagesArray = Array.from(resourcesMenuImages);
    if (resourceArray && resourceArray.length > 0) {
      resourceArray.forEach(element => {
        element.classList.remove("collapsed");
      });
    }

    if (resourcesMenuImagesArray && resourcesMenuImagesArray.length > 0) {
      resourcesMenuImagesArray.forEach(element => {
        element.classList.add("expanded");
      });
    }
    sessionStorage.setItem('resourceMenuState', 'expanded');
  }

  collapseResourceMenu() {
    const resourcesMenuElements = document.getElementsByClassName("resources_content")
    const resourceArray = Array.from(resourcesMenuElements);
    const resourcesMenuImages = document.getElementsByClassName("resources-menu-img")
    const resourcesMenuImagesArray = Array.from(resourcesMenuImages);
    if (resourceArray && resourceArray.length > 0) {
      resourceArray.forEach(element => {
        element.classList.add("collapsed")
      });
    }
    if (resourcesMenuImagesArray && resourcesMenuImagesArray.length > 0) {
      resourcesMenuImagesArray.forEach(element => {
        element.classList.remove("expanded");
      });
    }
    sessionStorage.setItem('resourceMenuState', 'collapsed');
  }

  toggleProgramsMenu() {
    if (sessionStorage.getItem('sideNavState')!== 'collapsed') {
      const programMenuElements = document.getElementsByClassName("program_content")
      const programArray = Array.from(programMenuElements);
      const hasCollapsed = programArray.some(element => element.classList.contains("collapsed"));
      if (hasCollapsed) {
        this.expandProgramsMenu();
      } else {
        this.collapseProgramsMenu();
      }
    }else{
      this.expandNavigation()
      this.expandProgramsMenu()
    }
  }

  expandProgramsMenu() {
    const programMenuElements = document.getElementsByClassName("program_content")
    const programArray = Array.from(programMenuElements);
    const programMenuImages = document.getElementsByClassName("programs-menu-img")
    const programMenuImagesArray = Array.from(programMenuImages);
    if (programArray && programArray.length > 0) {
      programArray.forEach(element => {
        element.classList.remove("collapsed");
      });
    }
    if (programMenuImagesArray && programMenuImagesArray.length > 0) {
      programMenuImagesArray.forEach(element => {
        element.classList.add("expanded");
      });
    }
    //sessionStorage.setItem('programMenuState', 'expanded');
    document.cookie = "programMenuState=expanded; path=/";
  }

  collapseProgramsMenu() {
    const programMenuElements = document.getElementsByClassName("program_content")
    const programArray = Array.from(programMenuElements);
    const programMenuImages = document.getElementsByClassName("programs-menu-img")
    const programMenuImagesArray = Array.from(programMenuImages);
    if (programArray && programArray.length > 0) {
      programArray.forEach(element => {
        element.classList.add("collapsed");
      });
    }
    if (programMenuImagesArray && programMenuImagesArray.length > 0) {
      programMenuImagesArray.forEach(element => {
        element.classList.remove("expanded");
      });
    }
    //sessionStorage.setItem('programMenuState', 'collapsed');
    document.cookie = "programMenuState=collapsed; path=/";
  }

  toggleSideNavigation() {
    const screenWidth = document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    const featureFlagEnabled = this.data.get("featureFlagEnabled") === "true";
    const hideSidebar = this.data.get("hideSidebar") === "true";
    const sideNavMainContent = document.getElementById("side-nav-main-content");

    if (featureFlagEnabled && !hideSidebar && screenWidth > 834) {
      this.navigationTarget.style.display = "none";
      this.sideNavigationTarget.style.display = "block";
      if (sideNavMainContent) {
        sideNavMainContent.classList.remove("top-navigation");
      }
      if(document.getElementById("nav-push")){
        document.getElementById("nav-push").style.height = "0px"
      }
    } else {
      this.navigationTarget.style.display = "block";
      this.sideNavigationTarget.style.display = "none";
      if (sideNavMainContent) {
        sideNavMainContent.classList.add("top-navigation");
      }
      if(document.querySelector("header.navigation")){
        let headerHeight = document.querySelector("header.navigation").offsetHeight;
        document.getElementById("nav-push").style.height = headerHeight+"px"
      }
    }
  }

  toggleSadndwichBarNavigation(){
    const sideNavDiv = document.getElementById("sandwitch-bar-nav-div");
    sideNavDiv.classList.toggle("show");
  }

  toggleQuizMenu() {
    if (sessionStorage.getItem('sideNavState')!== 'collapsed') {
      const elements = document.getElementsByClassName("quiz_content")
      const array = Array.from(elements);
      const hasCollapsed = array.some(e => e.classList.contains("collapsed"));
      if (hasCollapsed) {
        this.expandQuizMenu();
      } else {
        this.collapseQuizMenu();
      }
    }else{
      this.expandNavigation()
      this.expandQuizMenu()
    }
  }

  expandQuizMenu() {
    const elements = document.getElementsByClassName("quiz_content")
    const array = Array.from(elements);
    const images = document.getElementsByClassName("quiz-menu-img")
    const imagesArray = Array.from(images);
    if (array && array.length > 0) {
      array.forEach(e => {
        e.classList.remove("collapsed");
      });
    }
    if (imagesArray && imagesArray.length > 0) {
      imagesArray.forEach(e => {
        e.classList.add("expanded");
      });
    }
    sessionStorage.setItem('quizMenuState', 'expanded');
  }

  collapseQuizMenu() {
    const elements = document.getElementsByClassName("quiz_content")
    const array = Array.from(elements);
    const images = document.getElementsByClassName("quiz-menu-img")
    const imagesArray = Array.from(images);
    if (array && array.length > 0) {
      array.forEach(e => {
        e.classList.add("collapsed");
      });
    }
    if (imagesArray && imagesArray.length > 0) {
      imagesArray.forEach(e => {
        e.classList.remove("expanded");
      });
    }
    sessionStorage.setItem('quizMenuState', 'collapsed');
  }

  toggleTodoMenu(){
    if (sessionStorage.getItem('sideNavState')!== 'collapsed') {
      const todoMenuElements = document.getElementsByClassName("todo_content")
      const todoArray = Array.from(todoMenuElements);
      const hasCollapsed = todoArray.some(element => element.classList.contains("collapsed"));
      if (hasCollapsed) {
        this.expandTodoMenu();
      } else {
        this.collapseTodoMenu();
      }
    }else{
      this.expandNavigation()
      this.expandTodoMenu()
    }
  }

  expandTodoMenu() {
    const todoMenuElements = document.getElementsByClassName("todo_content")
    const todoArray = Array.from(todoMenuElements);
    const todoMenuImages = document.getElementsByClassName("todo-menu-img")
    const todoMenuImagesArray = Array.from(todoMenuImages);
    if (todoArray && todoArray.length > 0) {
      todoArray.forEach(element => {
        element.classList.remove("collapsed");
      });
    }

    if (todoMenuImagesArray && todoMenuImagesArray.length > 0) {
      todoMenuImagesArray.forEach(element => {
        element.classList.add("expanded");
      });
    }
    sessionStorage.setItem('todoMenuState', 'expanded');
  }

  collapseTodoMenu() {
    const todoMenuElements = document.getElementsByClassName("todo_content")
    const todoArray = Array.from(todoMenuElements);
    const todoMenuImages = document.getElementsByClassName("todo-menu-img")
    const todoMenuImagesArray = Array.from(todoMenuImages);
    if (todoArray && todoArray.length > 0) {
      todoArray.forEach(element => {
        element.classList.add("collapsed")
      });
    }
    if (todoMenuImagesArray && todoMenuImagesArray.length > 0) {
      todoMenuImagesArray.forEach(element => {
        element.classList.remove("expanded");
      });
    }
    sessionStorage.setItem('todoMenuState', 'collapsed');
  }

  handleKeypressToggleNavigation(event){
    if (event.keyCode === 13) {
      event.preventDefault(); // Prevent the default action
      this.toggleNavigation(); // Call the same method as the click action
    }
  }

  handleKeypressToggleTodoMenu(event){
    if (event.keyCode === 13) {
      event.preventDefault(); // Prevent the default action
      this.toggleTodoMenu(); // Call the same method as the click action
    }
  }

  handleKeypressToggleQuizMenu(event){
    if (event.keyCode === 13) {
      event.preventDefault(); // Prevent the default action
      this.toggleQuizMenu(); // Call the same method as the click action
    }
  }

  handleKeypressToggleResourcesMenu(event){
    if (event.keyCode === 13) {
      event.preventDefault(); // Prevent the default action
      this.toggleResourcesMenu(); // Call the same method as the click action
    }
  }

  handleKeypressToggleProgramsMenu(event){
    if (event.keyCode === 13) {
      event.preventDefault(); // Prevent the default action
      this.toggleProgramsMenu(); // Call the same method as the click action
    }
  }

}
